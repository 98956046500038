export const IMAGE_PLACEHOLDER_COMPONENTS_TYPES = [
	'WPhoto',
	'ImageButton',
	'MediaPlayer',
	'StripColumnsContainer',
	'Column',
	'SlideShowSlide',
	'PageBackground',
	'HoverBox',
]
